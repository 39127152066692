import * as React from "react";

// styles
import { Page, Card, Button } from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";

const IndexPage = () => {
  const pageTitle = "Create draft";
  return (
    <Page title={pageTitle}>
      <TitleBar title={pageTitle} />
      <Card
        sectioned
        secondaryFooterActions={[{ content: "Cancel", destructive: false }]}
        primaryFooterAction={{ content: "Create Draft" }}
      >
        <p>Create new draft</p>
      </Card>
      <main>
        <title>Home Page</title>
        <h1> Congratulations </h1>
        <p>
          This doesn't do a thing 
          <span role="img" aria-label="Sunglasses smiley emoji">
            😎
          </span>
        </p>
      </main>
    </Page>
  );
};

export default IndexPage;
